
import { selectIsAuthenticated } from '../Redux/AuthRelated/AuthSlice.js';
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import FullLayout from "../layouts/FullLayout.js";
import ExpiredPage from '../views/Admin/Expired.js'
import AdminDashboard from "../views/Admin/AdminDashboard.js";
import AdminLogin from "../views/Login/Login.js";
import AdminRegister from "../views/Register/Register.js";
import Branches from '../views/Admin/Branch/Branches.js';
import EditBranch from '../views/Admin/Branch/EditBranch.js';
import CreateBranch from '../views/Admin/Branch/CreateBranch.js';
import Classes from '../views/Admin/Class/Classes.js';
import CreateClass from "../views/Admin/Class/CreateClass.js";
import EditClass from '../views/Admin/Class/EditClass.js';
// import PromoteStudents from "../views/Admin/Student/PromoteStudents.js";
import CreateStudent from "../views/Admin/Student/CreateStudent.js";
import Students from '../views/Admin/Student/Students.js';
import EditStudent from '../views/Admin/Student/EditStudent.js';
import SingleStudent from '../views/Admin/Student/SingleStudent.js';
import Certificate from '../views/Admin/Student/certificate.js';
import Document from '../views/Admin/Student/Document.js';

import StudentCertificate from '../views/Admin/Student/studentCertificate.js';
import StudentDocument from '../views/Admin/Student/studentDocument.js';

// import ImportStudent from '../views/Admin/Student/ImportStudent.js';

import Organization from '../views/Admin/Organization/Organization.js';
import EditOrganization from '../views/Admin/Organization/EditOrganization.js';

// import Fees from "../views/Admin/Finance/Fees/Fees.js";
// import FeesType from '../views/Admin/Finance/FeesType/FeesType.js';
// import EditFeesType from '../views/Admin/Finance/FeesType/EditFeesType.js';
// import CreateFeesType from '../views/Admin/Finance/FeesType/CreateFeesType.js';

// import CreateTransaction from '../views/Admin/Finance/Transaction/CreateTransaction.js';
// import UpdateTransaction from '../views/Admin/Finance/Transaction/UpdateTransaction.js';
// import SingleTransaction from '../views/Admin/Finance/Transaction/SingleTransaction.js';
// import InvoiceTransaction from '../views/Admin/Finance/Transaction/InvoiceTransaction.js';

import CreateAcademicYear from '../views/Admin/Academics/CreateAcademicYear.js';

import VerifyEmail from '../views/Register/VerifyEmail.js'
import ForgotPassword from '../views/Admin/forgot/ForgotPassword.js'
import PasswordReset from '../views/Admin/forgot/PasswordReset.js'



const ProtectedAdminDashboard = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />; 
  }
  return <FullLayout />;
};

const ThemeRoutes = [

  {
    path: "/auth/login",
    element: <AdminLogin />,
  },
  {
    path: "/auth/register",
    element: <AdminRegister />,
  },
  {
    path: '/verifyemail',
    element: <VerifyEmail />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    path: '/reset-password',
    element: <PasswordReset />
  },
  {  path: "/planexpired",
    element: (
     <ExpiredPage />
   )},
  {
    path: "/",
    element: <ProtectedAdminDashboard />,
    children: [

      { path: "/", element: <Navigate to="/admindashboard" /> },
    
      { path: "/admindashboard", exact: true, element: <AdminDashboard /> },
      { path: "/organization", exact: true, element: <Organization /> },

      
      { path: "/edit/organization/:id", exact: true, element: <EditOrganization /> },
      
      { path: "/create/academicyear", exact: true, element: <CreateAcademicYear /> },
      

      { path: "/branches", exact: true, element: <Branches /> },
      { path: "/createbranch", exact: true, element: <CreateBranch /> },
      { path: "/updatebranch/:id", exact: true, element: <EditBranch /> },

      { path: "/createclass", exact: true, element: <CreateClass /> },
      { path: "/classes", exact: true, element: <Classes /> },
      { path: "/updateclass/:id", exact: true, element: <EditClass /> },

      // { path: "/students/promote", exact: true, element: <PromoteStudents /> },

      { path: "/createstudent", exact: true, element: <CreateStudent /> },
      { path: "/students", exact: true, element: <Students /> },
      { path: "/updatestudent/:id", exact: true, element: <EditStudent /> },
      { path: "/singlestudent/:id", exact: true, element: <SingleStudent /> },
      { path: "/students/certificate", exact: true, element: <Certificate />},
      { path: "/students/document", exact: true, element: <Document />},
      { path: "/student/certificate/:id", exact: true, element: <StudentCertificate /> },
      { path: "/student/document/:id", exact: true, element: <StudentDocument /> },

      // { path: "/importstudent", exact: true, element: <ImportStudent /> },

      // { path: "/fees", exact: true, element: <Fees /> },
      // { path: "/feestype", exact: true, element: <FeesType /> },
      // { path: "/create/feestype", exact: true, element: <CreateFeesType /> },
      // { path: "/edit/feestype/:id", exact: true, element: <EditFeesType /> },

      // { path: "/create/transaction/:id", exact: true, element: <CreateTransaction /> },
      // { path: "/update/transaction/:id", exact: true, element: <UpdateTransaction /> },
      // { path: "/details/transaction/:id", exact: true, element: <SingleTransaction /> },
      // { path: "/invoice/transaction/:id", exact: true, element: <InvoiceTransaction /> },
   
      { path: "/*",  element: <Navigate to="/" /> },
    ],
  },
];



export default ThemeRoutes;
