import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import axiosInstance from "../../../middleware/axiosInstance";

const EditOrganization = () => {
  const navigate = useNavigate();
  const { id } = useParams();  

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    organizationName: "",
    adminName: "",
    establishmentDate: "",
    organizationId: "",
    subscriptionCreatedDate: "",
  });

  const fetchOrganization = async () => {
    try {
      const response = await axiosInstance.get(`/details/org/${id}`);
      const organization = response.data;

      setFormData({
        organizationName: organization.org_id.organizationName, 
        adminName: organization.name || "",
        establishmentDate: organization.org_id.orgEstablishmentDate
          ? new Date(organization.org_id.orgEstablishmentDate)
              .toISOString()
              .slice(0, 10) 
          : "",
        organizationId: organization.org_id._id,
        subscriptionCreatedDate: organization.org_id.createdAt
          ? new Date(organization.org_id.createdAt)
              .toISOString()
              .slice(0, 10) 
          : "",
      });
    } catch (error) {
      console.error("Error fetching organization data:", error);
    }
  };

  useEffect(() => {
    fetchOrganization(); 
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axiosInstance.put(`/update/org/${id}`, formData);
      navigate("/organization"); 
    } catch (error) {
      console.error("Error updating organization:", error);
    }
    setLoading(false);
  };

  return (

      <Card style={{ maxWidth: "1400px", margin: "0 auto" }}>
        <CardBody>
          <CardTitle tag="h3" className="mb-4 text-center">
            Edit Organization
          </CardTitle>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label for="organizationName">
                    <span style={{ fontWeight: "bold" }}>
                      Organization Name*
                    </span>
                  </Label>
                  <Input
                    type="text"
                    name="organizationName"
                    id="organizationName"
                    value={formData.organizationName}
                    onChange={handleChange}
                    required
                    disabled 
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="adminName">
                    <span style={{ fontWeight: "bold" }}>Admin Name*</span>
                  </Label>
                  <Input
                    type="text"
                    name="adminName"
                    id="adminName"
                    value={formData.adminName}
                    onChange={handleChange}
                    required
                    disabled 
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="subscriptionCreatedDate">
                    <span style={{ fontWeight: "bold" }}>
                     Subscription Created  Date
                    </span>
                  </Label>
                  <Input
                    type="date"
                    name="subscriptionCreatedDate"
                    id="    subscriptionCreatedDate"
                    value={formData.subscriptionCreatedDate}
                    onChange={handleChange}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="establishmentDate">
                    <span style={{ fontWeight: "bold" }}>
                      Establishment Date*
                    </span>
                  </Label>
                  <Input
                    type="date"
                    name="establishmentDate"
                    id="establishmentDate"
                    value={formData.establishmentDate}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="btn-no-radius theme-button"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
              <Button
                color="secondary"
                type="button"
                className="btn-no-radius mx-2"
                onClick={() => navigate("/organization")} 
              >
                Cancel
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>

  );
};

export default EditOrganization;
