import { Card, CardBody } from "reactstrap";

const TopCards = (props) => {

  
  return (
    <Card style={{ height: '100%' }} className="m-0   p-0">
      <CardBody>
        <div className="text-center">
          <div className={`circle-box lg-box d-inline-block ${props.bg}`}>
            <i className={props.icon}></i>
          </div>
          <div className="mt-3">
            <h4 className="mb-2 font-weight-bold">{props.title}</h4>
            <h5 className="text-muted">{props.earning}</h5>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default TopCards;
