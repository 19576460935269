import React, { useEffect, useState } from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import axiosInstance from '../../../middleware/axiosInstance';
import { Row, Col, CardTitle, CardText, Table, Button, Input, Spinner} from 'reactstrap';
import Swal from 'sweetalert2';
import { RxCross2 } from "react-icons/rx";
import { FiArrowLeft } from 'react-icons/fi';



const StudentDocuments = () => {

  const { id } = useParams();
  const [studentData, setStudentData] = useState(null);
  const navigate = useNavigate();

  const [uploading, setUploading] = useState({
    aadharCard: false,
    studentIDCard: false,
    birthCertificate: false,
    leavingCertificate: false,
    incomeCertificate: false,
    casteCertificate: false,
    casteValidity: false,
    bonafideCertificate: false,
    admissionConfirmation: false,
    previousschoolleaving:false,
  });

  const [documents, setDocuments] = useState({
    aadharCard: { file: null, preview: null, url: null, remark: "" },
    studentIDCard: { file: null, preview: null, url: null, remark: "" },
    birthCertificate: { file: null, preview: null, url: null, remark: "" },
    leavingCertificate: { file: null, preview: null, url: null, remark: "" },
    incomeCertificate: { file: null, preview: null, url: null, remark: "" },
    casteCertificate: { file: null, preview: null, url: null, remark: "" },
    casteValidity: { file: null, preview: null, url: null, remark: "" },
    bonafideCertificate: { file: null, preview: null, url: null, remark: "" },
    admissionConfirmation: { file: null, preview: null, url: null, remark: "" },
    previousschoolleaving: { file: null, preview: null, url: null, remark: "" }

  });


  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axiosInstance.get(`/singlestudent/${id}`);
        setStudentData(response.data);
 
        setDocuments(prevDocuments => {
          const updatedDocuments = { ...prevDocuments };
          
          const documentsData = response.data.documents || {};
        
          Object.keys(updatedDocuments).forEach(key => {
            if (documentsData[key]) {
              updatedDocuments[key].url = documentsData[key].url;
              updatedDocuments[key].remark = documentsData[key].remark || "";
            }
          });
        
          return updatedDocuments;
        });

       } catch (err) {
        console.error('Error fetching student data:', err);
        Swal.fire({
          title: 'Error!',
          text: 'There was an error fetching the student details',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    };
    fetchStudentData();

  }, [id]);

  const handleFileChange = (event, fieldName) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      if (file.type.includes('pdf')) {
        const blob = new Blob([reader.result], { type: 'application/pdf' });
        const preview = URL.createObjectURL(blob);
        setDocuments(prev => ({
          ...prev,
          [fieldName]: { file, preview, url: prev[fieldName].url, remark: prev[fieldName].remark  },
        }));
      } else {
        setDocuments(prev => ({
          ...prev,
          [fieldName]: { file, preview: reader.result, url: prev[fieldName].url, remark: prev[fieldName].remark  },
        }));
      }
     };

    if (file) {
      if (file.type.includes('pdf')) {
        reader.readAsArrayBuffer(file);
      } else {
        reader.readAsDataURL(file);
      }
    } else {
      setDocuments(prev => ({
        ...prev,
        [fieldName]: { file: null, preview: null},
      }));
    }
  };


  const handleRemarkChange = (event, fieldName) => {
    const remark = event.target.value;
    setDocuments(prev => ({
      ...prev,
      [fieldName]: { ...prev[fieldName], remark },
    }));
  };

  const handleUpload = async (fieldName) => {
    try {
      const formData = new FormData();
    if (documents[fieldName].file) {
      formData.append(fieldName, documents[fieldName].file);
    }
      
  
    formData.append(fieldName, documents[fieldName].remark);
  

    setUploading(prev => ({ ...prev, [fieldName]: true }));

       const response = await axiosInstance.put(`/updatestudentdoc/${id}`, formData);

      Swal.fire('Uploaded!', 'Your document has been uploaded.', 'success');

      setDocuments(prev => ({
        ...prev,
        [fieldName]: { ...prev[fieldName], url: response.data[fieldName]?.url , remark: response.data[fieldName]?.remark ?? '' ,file: null, preview: null } // Assuming the response contains the URL
      }));
      
     document.getElementById(`${fieldName}-input`).value = null;
 

    } catch (error) {
      console.error('Error uploading document:', error.response || error);
      setUploading(prev => ({ ...prev, [fieldName]: false }));
    }finally {
      setUploading(prev => ({ ...prev, [fieldName]: false }));
    }
  };
  
  const getInitial = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase();
  };


  if (!studentData) {
     return <>
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '90vh' }}>
          <Spinner color="primary" />
        </div>
    </>; 
  }

const handleDownload = async (url, filename) => {
    try {
    const mainresponse = await axiosInstance.post('/api/download', { url });
    if (mainresponse?.data?.url)
    {
       const response = await fetch(mainresponse.data.url);
      const blob = await response.blob();
      const contentType = response.headers.get("Content-Type");

      let extension = '';
  
      if (contentType) {
        extension = contentType.split("/")[1]; 
      } else {
        extension = url.split('.').pop().split(/\#|\?/)[0];
      }

      const finalFilename = `${studentData.roll}_${studentData.firstName}_${studentData.lastName}_${filename}.${extension}`; 
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', finalFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    } catch (error) {
      console.error("Download failed:", error);
      Swal.fire({
        title: 'Warning',
        text: 'Server is facing some issue, please try again later',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleDelete = async (fieldName) => {
    try {
       const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
      
        await axiosInstance.delete(`/deletestudentdoc/${id}`, { 
          data: { fieldName }  
        });

        Swal.fire('Deleted!', 'Your document has been deleted.', 'success');

        setDocuments(prev => ({
          ...prev,
          [fieldName]: { file: null, preview: null, url: null , remark:prev[fieldName].remark } 
        }));
      }

    } catch (error) {
      console.error('Error deleting document:', error.response || error);
    }
  };


  const handleClearFile = (fieldName) => {
    setDocuments(prev => ({
      ...prev,
      [fieldName]: { file: null, preview: null, url: prev[fieldName].url , remark: prev[fieldName].remark},
    }));
    document.getElementById(`${fieldName}-input`).value = null;  
  };

  const handleBackClick = () => {
    navigate("/students/document");  
  };


  return (
    <div>
       <button onClick={handleBackClick} className="floating-back-button">
        <FiArrowLeft size={24} />  
      </button>

      <div style={{
        backgroundColor: 'white',
        padding: '0px',
        borderRadius: '0px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        width: '100%',
         Width: '1200px',
        margin: '0px',
        overflowX:'hidden',
      }}>
        <Row className='p-0 m-0'>
          <Col md="4" style={{ display: 'flex', justifyContent: 'center' }}>
          {studentData.image ? (
            <img
            src={studentData.image || 'default-image-url'}
            alt="Student"
            style={{
              width: '200px',
              height: '200px',
              objectFit: 'contain',
              borderRadius: '0px',
              marginTop: '10px'
            }}
          /> ) : (
            <div
                      className="square"
                      style={{
                        width: '200px',
                        height: '200px',
                        backgroundColor: '#fb0f01',
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignSelf:"center",
                        fontSize: '60px',
                        fontWeight: 'bold',
                        marginBottom: '0px',
                      }}
                    >
                      {getInitial(studentData.firstName)}
                    </div>
                     )}
          </Col>
          
          <Col md="8" className="p-3">
            <CardTitle tag="h4" className="mb-3">
              {studentData.firstName} {studentData.lastName}
            </CardTitle>
            <CardText>
              <strong>Register Number:</strong> {studentData.registerNo}
            </CardText>
            <CardText> <strong>Branch: </strong>{studentData.branch_id ? `${studentData.branch_id.branchName}` : 'N/A'}</CardText>
            <CardText>
              <strong>Class:</strong> {studentData.class_id?.className}{studentData.section_id ? ` (${studentData.section_id.sectionName})` : ''}
            </CardText>
            <CardText>
              <strong>Roll Number:</strong> {studentData.roll}
            </CardText>
          </Col>
        </Row>
      </div>

      <div style={{ backgroundColor: '#f8f9fa', padding: '0px', marginTop: '20px' }}>
        <Table striped bordered  responsive>
          <thead>
            <tr>
              <th>Document</th>
              <th>Upload</th>
              <th>Preview</th>
              <th>Remark</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{width:'200px', wordWrap:'break-word' }} >Admission Confirmation</td>
            <td style={{ position: 'relative' , width:'250px'}}>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <Input
                id="admissionConfirmation-input"
                type="file"
                style={{ width: '100%', fontSize: '14px' }}
                onChange={(e) => handleFileChange(e, 'admissionConfirmation')}
              />
              {documents.admissionConfirmation.file && (
                <RxCross2
                  onClick={() => handleClearFile('admissionConfirmation')}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '8px',
                    cursor: 'pointer',
                    color: 'red',
                    fontSize: '20px',
                      backgroundColor:'#ffffff'
                  }}
                />
              )}
               <span style={{ fontSize: "12px", color: "#d83030" }}>Maximum Upload Limit: 5MB</span>
            </div>
          </td>
              <td style={{ height: '150px', width:'230px', minWidth:'230px'}}>
                {documents.admissionConfirmation.preview ? (
                  documents.admissionConfirmation.file.type.includes('image') ? (
                    <img src={documents.admissionConfirmation.preview} alt="Preview" style={{ width:'100%', height: '100%', objectFit: "contain" }} />
                  ) : documents.admissionConfirmation.file.type.includes('pdf') ? (
                    <iframe src={documents.admissionConfirmation.preview} style={{  width:'100%',  height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : null
                ) : documents.admissionConfirmation.url ? (
                  documents.admissionConfirmation.url.includes('pdf') ? (
                    <iframe src={documents.admissionConfirmation.url} style={{width:'100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : (
                    <img src={documents.admissionConfirmation.url} alt="Existing File" style={{ width:'100%',height: '100%', objectFit: "contain" }} />
                  )
                ) : null}
              </td>
              <td  style={{ width:'230px', height:'139px'}} >
                  <Input
                    type="textarea"
                    value={documents.admissionConfirmation.remark || ''}
                    placeholder="Enter your remark"
                    onChange={(e) => handleRemarkChange(e, "admissionConfirmation")}
                    style={{height:'139px', minWidth:'230px', width:'100%'}}
                  />
                </td>
              <td style={{height: '100px', maxWidth:'120px', width:'120px' }} >
                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Button color="primary" style={{ border: "none", fontSize:'14px' , padding:'5px' }} onClick={() => handleUpload('admissionConfirmation')}>
                {uploading.admissionConfirmation ? (
                      <>
                        <Spinner size="sm" />
                      </>
                    ) : (
                      'Save/Upload'
                    )}
                </Button>
                {documents.admissionConfirmation.url && (
                <>
                <Button 
                  color="primary" 
                  style={{ border: "none", marginTop: '10px' , fontSize:'14px' , padding:'5px'}} 
                  onClick={() => handleDownload(documents.admissionConfirmation.url, 'admission_confirmation')}
                >
                  Download
                 </Button>
                 <Button  
                      color="primary" 
                      style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px' }} 
                      onClick={() => handleDelete('admissionConfirmation')}
                    >
                      Delete
                    </Button>
                </>
              )}
               </div>
              </td>
            </tr>

            <tr>
              <td>Student ID Card</td>
              <td style={{ position: 'relative' ,  width:'250px' }}>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <Input
                id="studentIDCard-input"
                type="file"
                style={{ width: '100%', fontSize: '14px' }}
                onChange={(e) => handleFileChange(e, 'studentIDCard')}
              />
              {documents.studentIDCard.file && (
                <RxCross2
                  onClick={() => handleClearFile('studentIDCard')}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '8px',
                    cursor: 'pointer',
                    color: 'red',
                    fontSize: '20px',
                      backgroundColor:'#ffffff'
                  }}
                />
              )}
               <span style={{ fontSize: "12px", color: "#d83030" }}>Maximum Upload Limit: 5MB</span>
            </div>
          </td>
              <td style={{  height: '150px'  ,width:'230px', minWidth:'230px'}}>
                {documents.studentIDCard.preview ? (
                  documents.studentIDCard.file.type.includes('image') ? (
                    <img src={documents.studentIDCard.preview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  ) : documents.studentIDCard.file.type.includes('pdf') ? (
                    <iframe src={documents.studentIDCard.preview} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : null
                ) : documents.studentIDCard.url ? (
                  documents.studentIDCard.url.includes('pdf') ? (
                    <iframe src={documents.studentIDCard.url} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : (
                    <img src={documents.studentIDCard.url} alt="Existing File" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  )
                ) : null}
              </td>
              <td  style={{ width:'230px'}} >
                  <Input
                    type="textarea"
                    value={documents.studentIDCard.remark || ''}
                    placeholder="Enter your remark"
                    onChange={(e) => handleRemarkChange(e, "studentIDCard")}
                    style={{height:'139px', minWidth:'230px', width:'100%'}}
                  />
                </td>
              <td style={{height: '100px', maxWidth:'120px', width:'120' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Button color="primary" style={{ border: "none", fontSize:'14px' , padding:'5px' }} onClick={() => handleUpload('studentIDCard')}>
                {uploading.studentIDCard ? ( 
                      <>
                        <Spinner size="sm" />
                      </>
                    ) : (
                       'Save/Upload'
                    )}
                </Button>
                {documents.studentIDCard.url && (
                  <>
                <Button 
                  color="primary" 
                  style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                  onClick={() => handleDownload(documents.studentIDCard.url, 'Student_IDCARD')}
                >
                  Download
                 </Button>
                 <Button  
                 color="primary" 
                 style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                 onClick={() => handleDelete('studentIDCard')}
               >
                 Delete
               </Button>
               </>
              )}
              </div>
              </td>
            </tr>

            <tr>
              <td>Bonafide Certificate</td>
              <td style={{ position: 'relative' }}>
            <div style={{ position: 'relative',  width:'250px', display: 'inline-block' }}>
              <Input
                id="bonafideCertificate-input"
                type="file"
                style={{ width: '100%', fontSize: '14px' }}
                onChange={(e) => handleFileChange(e, 'bonafideCertificate')}
              />
              {documents.bonafideCertificate.file && (
                <RxCross2
                  onClick={() => handleClearFile('bonafideCertificate')}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '8px',
                    cursor: 'pointer',
                    color: 'red',
                    fontSize: '20px',
                    backgroundColor:'#ffffff'
                  }}
                />
              )}
               <span style={{ fontSize: "12px", color: "#d83030" }}>Maximum Upload Limit: 5MB</span>
            </div>
          </td>
              <td style={{ height: '150px',  width:'230px', minWidth:'230px' }}>
                {documents.bonafideCertificate.preview ? (
                  documents.bonafideCertificate.file.type.includes('image') ? (
                    <img src={documents.bonafideCertificate.preview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  ) : documents.bonafideCertificate.file.type.includes('pdf') ? (
                    <iframe src={documents.bonafideCertificate.preview} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : null
                ) : documents.bonafideCertificate.url ? (
                  documents.bonafideCertificate.url.includes('pdf') ? (
                    <iframe src={documents.bonafideCertificate.url} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : (
                    <img src={documents.bonafideCertificate.url} alt="Existing File" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  )
                ) : null}
              </td>
              <td  style={{ width:'230px'}} >
                  <Input
                    type="textarea"
                    value={documents.bonafideCertificate.remark || ''}
                    placeholder="Enter your remark"
                    onChange={(e) => handleRemarkChange(e, "bonafideCertificate")}
                    style={{height:'139px', minWidth:'230px', width:'100%'}}
                  />
                </td>
              <td style={{height: '100px', maxWidth:'120px', width:'120' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Button color="primary" style={{ border: "none" , fontSize:'14px' , padding:'5px' }} onClick={() => handleUpload('bonafideCertificate')}>
                {uploading.bonafideCertificate ? ( 
                      <>
                        <Spinner size="sm" />
                      </>
                    ) : (
                       'Save/Upload'
                    )}
                </Button>
                {documents.bonafideCertificate.url && (
                <>
                <Button 
                  color="primary" 
                  style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                  onClick={() => handleDownload(documents.bonafideCertificate.url, 'Bonafide_Certificate')}
                >
                  Download
                 </Button>
                 <Button  
                 color="primary" 
                 style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                 onClick={() => handleDelete('bonafideCertificate')}
                 >
                 Delete
               </Button>
                 </>
              )}
              </div>
              </td>
            </tr>
                       
            <tr>
              <td>Leaving Certificate</td>
               <td style={{ position: 'relative' }}>
            <div style={{ position: 'relative',  width:'250px', display: 'inline-block' }}>
              <Input
                id="leavingCertificate-input"
                type="file"
                style={{ width: '100%', fontSize: '14px' }}
                onChange={(e) => handleFileChange(e, 'leavingCertificate')}
              />
              {documents.leavingCertificate.file && (
                <RxCross2
                  onClick={() => handleClearFile('leavingCertificate')}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '8px',
                    cursor: 'pointer',
                    color: 'red',
                    fontSize: '20px',
                    backgroundColor:'#ffffff'
                  }}
                />
              )}
              <span style={{ fontSize: "12px", color: "#d83030" }}>Maximum Upload Limit: 5MB</span>
            </div>
          </td>
              <td style={{  width:'230px', minWidth:'230px', height: '150px' }}>
                {documents.leavingCertificate.preview ? (
                  documents.leavingCertificate.file.type.includes('image') ? (
                    <img src={documents.leavingCertificate.preview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  ) : documents.leavingCertificate.file.type.includes('pdf') ? (
                    <iframe src={documents.leavingCertificate.preview} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : null
                ) : documents.leavingCertificate.url ? (
                  documents.leavingCertificate.url.includes('pdf') ? (
                    <iframe src={documents.leavingCertificate.url} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : (
                    <img src={documents.leavingCertificate.url} alt="Existing File" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  )
                ) : null}
              </td>
               <td  style={{ width:'230px'}} >
                  <Input
                    type="textarea"
                    value={documents.leavingCertificate.remark || ''}
                    placeholder="Enter your remark"
                    onChange={(e) => handleRemarkChange(e, "leavingCertificate")}
                    style={{height:'139px', minWidth:'230px', width:'100%'}}
                  />
                </td>
              <td>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Button color="primary" style={{ border: "none", fontSize:'14px' , padding:'5px'  }} onClick={() => handleUpload('leavingCertificate')} >
                {uploading.leavingCertificate ? ( 
                      <>
                        <Spinner size="sm" />
                      </>
                    ) : (
                       'Save/Upload'
                    )}
                </Button>
                {documents.leavingCertificate.url && (
                <>
                <Button 
                  color="primary" 
                  style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                  onClick={() => handleDownload(documents.leavingCertificate.url, 'leaving_Certificate')}
                >
                  Download
                 </Button>
                 <Button 
                 color="primary" 
                 style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                 onClick={() => handleDelete('leavingCertificate')}
                 >
                 Delete
               </Button>
                 </>
              )}
              </div>
              </td>
            </tr>

            <tr>
              <td>Aadhar Card</td>
                <td style={{ position: 'relative' }}>
            <div style={{ position: 'relative',  width:'250px', display: 'inline-block' }}>
              <Input
                id="aadharCard-input"
                type="file"
                style={{ width: '100%', fontSize: '14px' }}
                onChange={(e) => handleFileChange(e, 'aadharCard')}
              />
              {documents.aadharCard.file && (
                <RxCross2
                  onClick={() => handleClearFile('aadharCard')}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '8px',
                    cursor: 'pointer',
                    color: 'red',
                    fontSize: '20px',
                    backgroundColor:'#ffffff'
                  }}
                />
              )}
               <span style={{ fontSize: "12px", color: "#d83030" }}>Maximum Upload Limit: 5MB</span>
            </div>
          </td>
              <td style={{  width:'230px', minWidth:'230px', height: '150px' }}>
                {documents.aadharCard.preview ? (
                  documents.aadharCard.file.type.includes('image') ? (
                    <img src={documents.aadharCard.preview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  ) : documents.aadharCard.file.type.includes('pdf') ? (
                    <iframe src={documents.aadharCard.preview} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : null
                ) : documents.aadharCard.url ? (
                  documents.aadharCard.url.includes('pdf') ? (
                    <iframe src={documents.aadharCard.url} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : (
                    <img src={documents.aadharCard.url} alt="Existing File" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  )
                ) : null}
              </td>
              <td  style={{ width:'230px'}} >
                  <Input
                    type="textarea"
                    value={documents.aadharCard.remark || ''}
                    placeholder="Enter your remark"
                    onChange={(e) => handleRemarkChange(e, "aadharCard")}
                    style={{height:'139px', minWidth:'230px', width:'100%'}}
                  />
                </td>
              <td>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Button color="primary" style={{ border: "none" , fontSize:'14px' , padding:'5px' }} onClick={() => handleUpload('aadharCard')} >
                {uploading.aadharCard ? ( 
                      <>
                        <Spinner size="sm" />
                      </>
                    ) : (
                       'Save/Upload'
                    )}
                </Button>
                {documents.aadharCard.url && (
                  <>
                <Button 
                  color="primary" 
                  style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                  onClick={() => handleDownload(documents.aadharCard.url, 'aadharCard_Certificate')}
                >
                  Download
                 </Button>
                 <Button 
                 color="primary" 
                 style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                 onClick={() => handleDelete('aadharCard')}
                 >
                 Delete
               </Button>
                 </>
              )}
              </div>
              </td>
            </tr>

            <tr>
              <td>Birth Certificate</td>
                <td style={{ position: 'relative' }}>
            <div style={{ position: 'relative', width: '250px', display: 'inline-block' }}>
              <Input
                id="birthCertificate-input"
                type="file"
                style={{ width: '100%', fontSize: '14px' }}
                onChange={(e) => handleFileChange(e, 'birthCertificate')}
              />
              {documents.birthCertificate.file && (
                <RxCross2
                  onClick={() => handleClearFile('birthCertificate')}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '8px',
                    cursor: 'pointer',
                    color: 'red',
                    fontSize: '20px',
                    backgroundColor:'#ffffff'
                  }}
                />
              )}
               <span style={{ fontSize: "12px", color: "#d83030" }}>Maximum Upload Limit: 5MB</span>
            </div>
          </td>
              <td style={{  width:'230px', minWidth:'230px', height: '150px' }}>
                {documents.birthCertificate.preview ? (
                  documents.birthCertificate.file.type.includes('image') ? (
                    <img src={documents.birthCertificate.preview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  ) : documents.birthCertificate.file.type.includes('pdf') ? (
                    <iframe src={documents.birthCertificate.preview} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : null
                ) : documents.birthCertificate.url ? (
                  documents.birthCertificate.url.includes('pdf') ? (
                    <iframe src={documents.birthCertificate.url} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : (
                    <img src={documents.birthCertificate.url} alt="Existing File" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  )
                ) : null}
              </td>
              <td  style={{ width:'230px'}} >
                  <Input
                    type="textarea"
                    value={documents.birthCertificate.remark || ''}
                    placeholder="Enter your remark"
                    onChange={(e) => handleRemarkChange(e, "birthCertificate")}
                    style={{height:'139px', minWidth:'230px', width:'100%'}}
                  />
                </td>
              <td>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Button color="primary" style={{ border: "none" , fontSize:'14px' , padding:'5px' }} onClick={() => handleUpload('birthCertificate')} >
                {uploading.birthCertificate ? ( 
                      <>
                        <Spinner size="sm" />
                      </>
                    ) : (
                       'Save/Upload'
                    )}
                </Button>
                {documents.birthCertificate.url && (
                  <>
                <Button 
                  color="primary" 
                  style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                  onClick={() => handleDownload(documents.birthCertificate.url, 'birth_Certificate')}
                >
                  Download
                 </Button>
                 <Button  
                 color="primary" 
                 style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                 onClick={() => handleDelete('birthCertificate')}
                 >
                 Delete
               </Button>
               </>
              )}
              </div>
              </td>
            </tr>
            
            <tr>
              <td>Income Certificate</td>
                <td style={{ position: 'relative' }}>
            <div style={{ position: 'relative', width: '250px', display: 'inline-block' }}>
              <Input
                id="incomeCertificate-input"
                type="file"
                style={{ width: '100%', fontSize: '14px' }}
                onChange={(e) => handleFileChange(e, 'incomeCertificate')}
              />
              {documents.incomeCertificate.file && (
                <RxCross2
                  onClick={() => handleClearFile('incomeCertificate')}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '8px',
                    cursor: 'pointer',
                    color: 'red',
                    fontSize: '20px',
                    backgroundColor:'#ffffff'
                  }}
                />
              )}
               <span style={{ fontSize: "12px", color: "#d83030" }}>Maximum Upload Limit: 5MB</span>
            </div>
          </td>
              <td style={{ width:'230px', minWidth:'230px', height: '150px' }}>
                {documents.incomeCertificate.preview ? (
                  documents.incomeCertificate.file.type.includes('image') ? (
                    <img src={documents.incomeCertificate.preview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  ) : documents.incomeCertificate.file.type.includes('pdf') ? (
                    <iframe src={documents.incomeCertificate.preview} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : null
                ) : documents.incomeCertificate.url ? (
                  documents.incomeCertificate.url.includes('pdf') ? (
                    <iframe src={documents.incomeCertificate.url} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : (
                    <img src={documents.incomeCertificate.url} alt="Existing File" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  )
                ) : null}
              </td>
              <td  style={{ width:'230px'}} >
                  <Input
                    type="textarea"
                    value={documents.incomeCertificate.remark || ''}
                    placeholder="Enter your remark"
                    onChange={(e) => handleRemarkChange(e, "incomeCertificate")}
                    style={{height:'139px', minWidth:'230px', width:'100%'}}
                  />
                </td>
              <td>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Button color="primary" style={{ border: "none", fontSize:'14px' , padding:'5px'  }} onClick={() => handleUpload('incomeCertificate')} >
                {uploading.incomeCertificate ? ( 
                      <>
                        <Spinner size="sm" />
                      </>
                    ) : (
                       'Save/Upload'
                    )}
                </Button>
                {documents.incomeCertificate.url && (
                <>
                <Button 
                  color="primary" 
                  style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                  onClick={() => handleDownload(documents.incomeCertificate.url, 'Income_Certificate')}
                >
                  Download
                 </Button>
                  <Button  
                  color="primary" 
                  style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                  onClick={() => handleDelete('incomeCertificate')}
                  >
                  Delete
                </Button>
                </>
              )}
              </div>
              </td>
            </tr>
                     
            <tr>
              <td>Caste Certificate</td>
                <td style={{ position: 'relative' }}>
            <div style={{ position: 'relative', width: '250px', display: 'inline-block' }}>
              <Input
                id="casteCertificate-input"
                type="file"
                style={{ width: '100%', fontSize: '14px' }}
                onChange={(e) => handleFileChange(e, 'casteCertificate')}
              />
              {documents.casteCertificate.file && (
                <RxCross2
                  onClick={() => handleClearFile('casteCertificate')}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '8px',
                    cursor: 'pointer',
                    color: 'red',
                    fontSize: '20px',
                    backgroundColor:'#ffffff'
                  }}
                />
              )}
               <span style={{ fontSize: "12px", color: "#d83030" }}>Maximum Upload Limit: 5MB</span>
            </div>
          </td>
              <td style={{  width:'230px', minWidth:'230px', height: '150px' }}>
                {documents.casteCertificate.preview ? (
                  documents.casteCertificate.file.type.includes('image') ? (
                    <img src={documents.casteCertificate.preview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  ) : documents.casteCertificate.file.type.includes('pdf') ? (
                    <iframe src={documents.casteCertificate.preview} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : null
                ) : documents.casteCertificate.url ? (
                  documents.casteCertificate.url.includes('pdf') ? (
                    <iframe src={documents.casteCertificate.url} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : (
                    <img src={documents.casteCertificate.url} alt="Existing File" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  )
                ) : null}
              </td>
              <td  style={{ width:'230px'}} >
                  <Input
                    type="textarea"
                    value={documents.casteCertificate.remark || ''}
                    placeholder="Enter your remark"
                    onChange={(e) => handleRemarkChange(e, "casteCertificate")}
                    style={{height:'139px', minWidth:'230px', width:'100%'}}
                  />
                </td>
              <td>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Button color="primary" style={{ border: "none", fontSize:'14px' , padding:'5px'  }} onClick={() => handleUpload('casteCertificate')} >
                {uploading.casteCertificate ? ( 
                      <>
                        <Spinner size="sm" />
                      </>
                    ) : (
                       'Save/Upload'
                    )}
                </Button>
                {documents.casteCertificate.url && (
                <>
                <Button 
                  color="primary" 
                  style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                  onClick={() => handleDownload(documents.casteCertificate.url, 'Caste_Certificate')}
                >
                  Download
                 </Button>
                 <Button 
                 color="primary" 
                 style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                 onClick={() => handleDelete('casteCertificate')}
                 >
                 Delete
               </Button>
                 </>
              )}
              </div>
              </td>
            </tr>

            <tr>
              <td>Caste Validity</td>
               <td style={{ position: 'relative' }}>
            <div style={{ position: 'relative', width: '250px', display: 'inline-block' }}>
              <Input
                id="casteValidity-input"
                type="file"
                style={{ width: '100%', fontSize: '14px' }}
                onChange={(e) => handleFileChange(e, 'casteValidity')}
              />
              {documents.casteValidity.file && (
                <RxCross2
                  onClick={() => handleClearFile('casteValidity')}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '8px',
                    cursor: 'pointer',
                    color: 'red',
                    fontSize: '20px',
                    backgroundColor:'#ffffff'
                  }}
                />
              )}
               <span style={{ fontSize: "12px", color: "#d83030" }}>Maximum Upload Limit: 5MB</span>
            </div>
          </td>
              <td style={{  width:'230px', minWidth:'230px', height: '150px' }}>
                {documents.casteValidity.preview ? (
                  documents.casteValidity.file.type.includes('image') ? (
                    <img src={documents.casteValidity.preview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  ) : documents.casteValidity.file.type.includes('pdf') ? (
                    <iframe src={documents.casteValidity.preview} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : null
                ) : documents.casteValidity.url ? (
                  documents.casteValidity.url.includes('pdf') ? (
                    <iframe src={documents.casteValidity.url} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : (
                    <img src={documents.casteValidity.url} alt="Existing File" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  )
                ) : null}
              </td>
              <td  style={{ width:'230px'}} >
                  <Input
                    type="textarea"
                    value={documents.casteValidity.remark || ''}
                    placeholder="Enter your remark"
                    onChange={(e) => handleRemarkChange(e, "casteValidity")}
                    style={{height:'139px', minWidth:'230px', width:'100%'}}
                  />
                </td>
              <td>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Button color="primary" style={{ border: "none", fontSize:'14px' , padding:'5px'  }} onClick={() => handleUpload('casteValidity')} >
                {uploading.casteValidity ? ( 
                      <>
                        <Spinner size="sm" />
                      </>
                    ) : (
                      'Save/Upload'
                    )}
                </Button>
                {documents.casteValidity.url && (
                <>
                <Button 
                  color="primary" 
                  style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                  onClick={() => handleDownload(documents.casteValidity.url, 'CasteValidity_Certificate')}
                >
                  Download
                 </Button>
                  <Button 
                  color="primary" 
                  style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                  onClick={() => handleDelete('casteValidity')}
                  >
                  Delete
                </Button>
                </>
              )}
              </div>
              </td>
            </tr>

            <tr>
              <td>Previous School Leaving Certificate</td>
               <td style={{ position: 'relative' }}>
            <div style={{ position: 'relative', width: '250px', display: 'inline-block' }}>
              <Input
                id="previousschoolleaving-input"
                type="file"
                style={{ width: '100%', fontSize: '14px' }}
                onChange={(e) => handleFileChange(e, 'previousschoolleaving')}
              />
              {documents.previousschoolleaving.file && (
                <RxCross2
                  onClick={() => handleClearFile('previousschoolleaving')}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '8px',
                    cursor: 'pointer',
                    color: 'red',
                    fontSize: '20px',
                    backgroundColor:'#ffffff'
                  }}
                />
              )}
                <span style={{ fontSize: "12px", color: "#d83030" }}>Maximum Upload Limit: 5MB</span>
            </div>
          </td>
              <td style={{  width:'230px', minWidth:'230px', height: '150px' }}>
                {documents.previousschoolleaving.preview ? (
                  documents.previousschoolleaving.file.type.includes('image') ? (
                    <img src={documents.previousschoolleaving.preview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  ) : documents.previousschoolleaving.file.type.includes('pdf') ? (
                    <iframe src={documents.previousschoolleaving.preview} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : null
                ) : documents.previousschoolleaving.url ? (
                  documents.previousschoolleaving.url.includes('pdf') ? (
                    <iframe src={documents.previousschoolleaving.url} style={{ width: '100%', height: '100%', objectFit: "contain" }} title="PDF Preview"></iframe>
                  ) : (
                    <img src={documents.previousschoolleaving.url} alt="Existing File" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
                  )
                ) : null}
              </td>
              <td  style={{ width:'230px'}} >
                  <Input
                    type="textarea"
                    value={documents.previousschoolleaving.remark || ''}
                    placeholder="Enter your remark"
                    onChange={(e) => handleRemarkChange(e, "previousschoolleaving")}
                    style={{height:'139px', minWidth:'230px', width:'100%'}}
                  />
                </td>
              <td>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Button color="primary" style={{ border: "none", fontSize:'14px' , padding:'5px'  }} onClick={() => handleUpload('previousschoolleaving')} >
                {uploading.previousschoolleaving ? ( 
                      <>
                        <Spinner size="sm" />
                      </>
                    ) : (
                      'Save/Upload'
                    )}
                </Button>
                {documents.previousschoolleaving.url && (
                <>
                <Button 
                  color="primary" 
                  style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                  onClick={() => handleDownload(documents.previousschoolleaving.url, 'previousschoolleaving_Certificate')}
                >
                  Download
                 </Button>
                  <Button 
                  color="primary" 
                  style={{ border: "none", marginTop: '10px', fontSize:'14px' , padding:'5px'  }} 
                  onClick={() => handleDelete('previousschoolleaving')}
                  >
                  Delete
                </Button>
                </>
              )}
              </div>
              </td>
            </tr>

          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default StudentDocuments;
