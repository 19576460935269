import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Button,
  Spinner,
  Table,
  Row,
  Col
} from 'reactstrap';
import { FaRegEdit, FaFileAlt } from 'react-icons/fa';
import { AiOutlineDelete} from "react-icons/ai";
import { NavLink, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../middleware/axiosInstance';

const Classes = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); 

  const [formData, setFormData] = useState({ branchId: '' });
  const [branches, setBranches] = useState([]);
  const [allClasses, setAllClasses] = useState([]); 
  const [filteredClasses, setFilteredClasses] = useState([]); 
  const [noDataFound, setNoDataFound] = useState(false);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axiosInstance.get('/branches');
        setBranches(response.data);
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };

    fetchBranches();
  
    
    const fetchClasses = async () => {
      try {
        const response = await axiosInstance.get('/classes');
        setAllClasses(response.data);
        setFilteredClasses(response.data); 
        setNoDataFound(response.data.length === 0);
      } catch (error) {
        console.error('Failed to fetch classes:', error);
      }finally {
        setLoading(false);
      }
    };

    fetchBranches();
    fetchClasses();
  }, [token]);

  const handleEditClass = (id) => {
    navigate(`/updateclass/${id}`); 
  };


  useEffect(() => {
    if (formData.branchId) {
      const filtered = allClasses.filter(
        classItem => classItem.branch_id?._id === formData.branchId
      );
      setFilteredClasses(filtered);

      setNoDataFound(allClasses.length === 0 && filtered.length === 0);
    } else {
      setFilteredClasses(allClasses);

      setNoDataFound(allClasses.length === 0);
    }
  }, [formData.branchId, allClasses]);

  const options = branches.map(branch => ({
    value: branch._id,
    label: branch.branchName,
  }));

  const handleBranchChange = selectedOption => {
    setFormData({ ...formData, branchId: selectedOption ? selectedOption.value : '' });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#FB0F01' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px #FB0F01' : null,
      borderWidth: state.isFocused ? '0px' : '1px', 
      transition: 'border-color 0.2s, box-shadow 0.2s', 
      '&:hover': {
        borderColor: '#FB0F01',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#FDD7C2' : '#FFFFFF',
      color: state.isFocused ? 'black' : 'black',
      '&:active': {
        backgroundColor: '#E8F0FE',
      },
    }),
  };

  const renderTable = () => (
    <div style={{ overflowX: 'auto' }}>
    <Table bordered striped responsive className='mt-3'>
      <thead style={{ backgroundColor: '#f8f9fa', color: '#495057' }}>
        <tr>
          <th>Class Name</th>
          <th>Sections</th>
          <th>Branch</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {filteredClasses.length > 0 ? filteredClasses.map(classItem => (
          <tr key={classItem._id}>
            <td>{classItem.className}</td>
            <td>
            {classItem.sections && classItem.sections.length > 0
              ? classItem.sections.map(section => section.sectionName).join(', ') 
              : 'No Sections'}
          </td>
            <td>{classItem.branch_id?.branchName || 'N/A'}</td>
            <td>
              <FaRegEdit onClick={() => handleEditClass(classItem._id)} style={{ cursor: 'pointer', margin: '0 6px' }} />
              <AiOutlineDelete onClick={() => handleDeleteClass(classItem._id)} style={{ cursor: 'pointer', margin: '0 6px' }} />
            </td>
          </tr>
        )) : (
          <tr>
            <td colSpan="5" className="text-center">
              <FaFileAlt className='mt-3' size={70} color="#888" />
              <h4 className="alert-heading mb-3 mt-3">No Classes Found</h4>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
     <style jsx="true">{`
      table td, table th {
        white-space: nowrap; 
      }

      @media (max-width: 768px) {
        table td, table th {
          padding: 5px;
        }
      }

      @media (max-width: 576px) {
        table td, table th {
        }
      }
    `}</style>
  </div>
  );

  const handleDeleteClass = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/deleteclass/${id}`);
          setAllClasses(allClasses.filter(cls => cls._id !== id));
          setFilteredClasses(filteredClasses.filter(cls => cls._id !== id));
        
          setNoDataFound(filteredClasses.length === 1);

        } catch (error) {
          console.error('Error deleting class:', error);
        }
      }
    });
  };



return (
  <>
    {loading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '90vh' }}>
        <Spinner color="primary" />
      </div>
    ) : noDataFound ? (
      <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '90vh' }}>
        <div className="text-center">
          <div className="d-flex flex-column align-items-center">
            <FaFileAlt size={90} color="#888" />
            <h4 className="alert-heading mb-3 mt-3">No Classes Found</h4>
            <NavLink to="/createclass">
              <Button className='btn-no-radius theme-button' color="primary">Add New Class</Button>
            </NavLink>
          </div>
        </div>
      </div>
    ) : (
      <div>
            <Card  style={{ boxShadow: 'none', marginBottom: '10px', borderRadius: '0' }}>
        <div className="d-flex justify-content-between align-items-center  px-3 pt-1">
          <CardTitle className='mt-2' tag="h4">
            Class List
          </CardTitle>
          <NavLink to="/createclass">
            <Button className="btn-no-radius" color="primary">Add New Class</Button>
          </NavLink>
        </div>
        
        <hr className='mt-1' />
        <div className="px-3 pt-0">
          <Form>
          <Row>
      <Col md={3}>
        <FormGroup>
          <Label for="branchId">
            <span style={{ fontWeight: 'bold' }}>Branch</span>
          </Label>
          <Select
            id="branchId"
            options={options}
            value={options.find(option => option.value === formData.branchId) || null}
            onChange={handleBranchChange}
            placeholder="Select a Branch"
            isClearable 
            styles={customStyles}
          />
        </FormGroup>
      </Col>
    </Row>
          </Form>
          </div>
          </Card>
          <Card  className=" px-3 pt-0" style={{ borderRadius: '0', minHeight: '400px'}}>

          {renderTable()}
        </Card>
      </div>
    )}
  </>
);
};

export default Classes;