import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Col, Row, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../middleware/axiosInstance';
import StateCityData from './StateCity.json';
import Select from 'react-select';
import { RxCross2 } from "react-icons/rx";

const CreateBranch = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    branchName: '',
    organizationName: '',
    principalName: '',
    email: '',
    phoneNo: '',
    website: '',
    affiliationNo: '',
    schoolCode: '',
    uDiseNo: '',
    city: '',
    state: '',
    district: '',
    address: '',
    pincode: '',
    image: null,
    principalSignImage: null
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    branchName: '',
    organizationName: '',
    principalName: '',
    email: '',
    phoneNo: '',
    website: '',
    affiliationNo: '',
    schoolCode: '',
    uDiseNo: '',
    pincode: ''
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [signImagePreview, setsignImagePreview] = useState(null);

  const fetchOrganization = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/admindashboard');
      const organizationName = response.data.organization;
      setFormData((prevFormData) => ({
        ...prevFormData,
        organizationName: organizationName
      }));
    } catch (err) {
      console.error('Error fetching organization data:', err);
      setErrors('Failed to fetch organization data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganization();
  }, []);

  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    const stateOptions = StateCityData.states.map((state) => ({
      value: state.state,
      label: state.state,
    }));
    setStates(stateOptions);

    setFormData((prevFormData) => ({
      ...prevFormData,
      state: '',
    }));
  }, []);

  const handleStateChange = (selectedOption) => {
    const stateValue = selectedOption ? selectedOption.value : '';

    setFormData((prevFormData) => ({
      ...prevFormData,
      state: stateValue,
      district: '', 
    }));

    setDistricts([]);

    if (stateValue) {
      const selectedState = StateCityData.states.find(
        (state) => state.state === stateValue
      );
      setDistricts(
        selectedState
          ? selectedState.district.map((district) => ({
            value: district,
            label: district,
          }))
          : []
      );
    }
  };

  const handleDistrictChange = (selectedOption) => {
    const districtValue = selectedOption ? selectedOption.value : '';
    setFormData((prevFormData) => ({
      ...prevFormData,
      district: districtValue,
    }));
  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'uDiseNo') {
      if (/^\d{0,12}$/.test(value)) {
        setFormData({ ...formData, [name]: value });

        if (value === '') {
          setErrors({ ...errors, [name]: '' }); 
        }
        else if (value.length === 11) {
          setErrors({ ...errors, [name]: '' });
        } else {
          setErrors({ ...errors, [name]: 'UDISE Number must be exactly 11 digits.' });
        }
      }
    }
    else if (name === 'phoneNo') {
      if (/^\d{0,10}$/.test(value)) {
        setFormData({ ...formData, [name]: value });

        if (value === '') {
          setErrors({ ...errors, [name]: '' }); 
        }
        else if (value.length === 10) {
          setErrors({ ...errors, [name]: '' }); 
        } else {
          setErrors({ ...errors, [name]: 'Phone Number must be exactly 10 digits.' });
        }
      }
    }

    else if (name === 'website') {
      if (value.length <= 2000) {
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, website: '' }); 
      } else {
        setErrors({ ...errors, website: 'Website URL must be less than or equal to 2000 characters.' });
      }
    }

    else if (name === 'email') {
      if (value.length <= 2000) {
        setFormData({ ...formData, [name]: value });

        if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
          setErrors({ ...errors, email: '' }); 
        } else {
          setErrors({ ...errors, email: 'Email format is invalid.' });
        }
      } else {
        setErrors({ ...errors, email: 'Email must not exceed 2000 characters.' });
      }
    }

    else if (name === 'affiliationNo') {
      if (/^\d{0,12}$/.test(value)) {
        setFormData({ ...formData, [name]: value });
        if (value.length < 12) {
          setErrors({ ...errors, [name]: '' });
        } else {
          setErrors({ ...errors, [name]: 'Affiliation No. must be less then 12 digits.' });
        }
      }
    }

    else if (name === 'schoolCode') {
      if (/^\d{0,12}$/.test(value)) {
        setFormData({ ...formData, [name]: value });
        if (value.length < 12) {
          setErrors({ ...errors, [name]: '' });
        } else {
          setErrors({ ...errors, [name]: 'School Code must be less then 12 digits.' });
        }
      }
    }

    else if (name === 'branchName') {
      if (/^[A-Za-z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(value)) {
        const words = value.trim().split(/\s+/);
        const branchWordCount = words.length;

        if (branchWordCount > 40) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            branchName: 'Branch Name must be between 1 to 40 words.',
          }));
          return;
        }

        const wordLengthExceeded = words.some(word => word.length > 50);
        if (wordLengthExceeded) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            branchName: 'Each word in Branch Name must be up to 50 characters long.',
          }));
          return;
        }

        setFormData({ ...formData, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Branch Name must contain only letters, numbers, spaces, and special characters.',
        }));
      }
    }
    else if (name === 'principalName') {
      const words = value.trim().split(/\s+/);
      const wordCount = words.length;

      if (wordCount > 40) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Principal Name must be between 1 to 40 words.',
        }));
        return; 
      }

      const wordLengthExceeded = words.some(word => word.length > 50);
      if (wordLengthExceeded) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Each word in Principal Name must be up to 50 characters long.',
        }));
        return;
      }

      setFormData({ ...formData, [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
    else if (name === 'city') {
      const isValidCity = /^[A-Za-z\s]*$/.test(value);
      const words = value.trim().split(/\s+/);
      const wordCount = words.length;
      const isSingleWordValid = words.every(word => word.length <= 50);

      if (isValidCity && wordCount <= 40 && isSingleWordValid) {
        setFormData({ ...formData, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
      } else {
        if (wordCount > 40) {
          setErrors({ ...errors, [name]: 'City Name must be less than or equal to 40 words.' });
        } else if (!isSingleWordValid) {
          setErrors({ ...errors, [name]: 'Each word must be less than or equal to 50 characters.' });
        } else {
          setErrors({ ...errors, [name]: 'City Name must only contain letters and spaces.' });
        }
      }
    }
    else if (name === 'address') {
      const isValidAddress = /^[\s\S]*$/.test(value);
      const words = value.trim().split(/\s+/);
      const wordCount = words.length;
      const isSingleWordValid = words.every(word => word.length <= 50);

      if (isValidAddress && wordCount <= 40 && isSingleWordValid) {
        setFormData({ ...formData, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, address: '' }));
      } else {
        if (wordCount > 40) {
          setErrors({ ...errors, address: 'Address must be less than or equal to 40 words.' });
        } else if (!isSingleWordValid) {
          setErrors({ ...errors, address: 'Each word must be less than or equal to 50 characters.' });
        } else {
          setErrors({ ...errors, address: 'Address must be valid.' });
        }
      }
    }
    else if (name === 'pincode') {
      if (/^\d{0,10}$/.test(value)) {
        setFormData({ ...formData, [name]: value });

        if (value === '') {
          setErrors({ ...errors, [name]: '' });
        }
        else if (value.length === 6) {
          setErrors({ ...errors, [name]: '' });
        } else {
          setErrors({ ...errors, [name]: 'PIN Code must be exactly 6 digits.' });
        }
      }
    }

    else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    setFormData({
      ...formData,
      [name]: file
    });

    if (file) {
      const previewUrl = URL.createObjectURL(file);
      name === 'image' ? setImagePreview(previewUrl) : setsignImagePreview(previewUrl);
    }
  };

  const handleClearFile = (name) => {
    setFormData({
      ...formData,
      [name]: null
    });

    if (name === 'image') {
      setImagePreview(null);
    } else {
      setsignImagePreview(null);
    }

    document.getElementById(name).value = ''; 
  };

  const handleCreateBranch = async (e) => {
    e.preventDefault();

    if (formData.uDiseNo && formData.uDiseNo.length !== 11) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        uDiseNo: 'UDISE Number must be exactly 11 digits.',
      }));
      return; 
    }

    if (formData.phoneNo && formData.phoneNo.length !== 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNo: 'Phone Number must be exactly 10 digits.',
      }));
      return; 
    }
    if (formData.pincode && formData.pincode.length !== 6) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        pincode: 'PIN Code must be exactly 6 digits.',
      }));
      return;
    }

    if (formData.affiliationNo && formData.affiliationNo.length > 12) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        affiliationNo: 'Affiliation No. must be less then 12 digits.',
      }));
      return;
    }

    if (formData.schoolCode && formData.schoolCode.length > 12) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        schoolCode: 'School Code must be less then 12 digits.',
      }));
      return; 
    }

    if (formData.website && formData.website.length > 2000) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        website: 'website length must be less then 2000 characters.',
      }));
      return;
    }

    const branchWordCount = formData.branchName.trim().split(/\s+/).length;

    if (branchWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        branchName: 'Branch Name must be between 1 to 40 words.',
      }));
      return;
    }
    const principalWordCount = formData.principalName.trim().split(/\s+/).length;
    if (principalWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        principalName: 'Principal Name must be between 1 to 40 words.',
      }));
      return;
    }

    const cityWordCount = formData.city.trim().split(/\s+/).length;
    if (cityWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        city: 'city Name must be between 1 to 40 words.',
      }));
      return;
    }

    const addressWordCount = formData.address.trim().split(/\s+/).length;
    if (addressWordCount > 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: 'address must be between 1 to 40 words.',
      }));
      return;
    }

    setLoading(true);

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      await axiosInstance.post('/createbranch', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      navigate("/branches");
    } catch (err) {
      console.error("Error creating branch:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/branches");
  };


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#FB0F01' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px #FB0F01' : null,
      borderWidth: state.isFocused ? '0px' : '1px',
      transition: 'border-color 0.2s, box-shadow 0.2s',
      '&:hover': {
        borderColor: '#FB0F01',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#FDD7C2' : '#FFFFFF',
      color: state.isFocused ? 'black' : 'black',
      '&:active': {
        backgroundColor: '#E8F0FE',
      },
    }),
  };


  return (
    <div>
      <Card style={{ maxWidth: '1400px', margin: '0 auto' }}>
        <CardBody>
          <CardTitle tag="h3" className="mb-1 text-center">Add New Branch</CardTitle>
          <Form className='mt-5' onSubmit={handleCreateBranch}>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label for="organizationName"><span style={{ fontWeight: "bold" }}>Organization*</span></Label>
                  <Input
                    type="text"
                    name="organizationName"
                    id="organizationName"
                    value={formData.organizationName}
                    onChange={handleChange}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="branchName">
                    <span style={{ fontWeight: "bold" }}>Branch*</span>
                  </Label>
                  <Input
                    type="text"
                    name="branchName"
                    id="branchName"
                    value={formData.branchName}
                    onChange={handleChange}
                    required
                  />
                  {errors.branchName && (
                    <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                      {errors.branchName}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="principalName">
                    <span style={{ fontWeight: "bold" }}>Principal*</span>
                  </Label>
                  <Input
                    type="text"
                    name="principalName"
                    id="principalName"
                    value={formData.principalName}
                    onChange={handleChange}
                    required
                  />
                  {errors.principalName && (
                    <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                      {errors.principalName}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="email"><span style={{ fontWeight: "bold" }}>Email</span></Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                      {errors.email}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="phoneNo">
                    <span style={{ fontWeight: "bold" }}>Phone Number</span>
                  </Label>
                  <Input
                    type="text"
                    name="phoneNo"
                    id="phoneNo"
                    value={formData.phoneNo}
                    onChange={handleChange}
                    maxLength="10" 
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ''); 
                    }}
                  />
                  {errors.phoneNo && (
                    <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                      {errors.phoneNo}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="website">
                    <span style={{ fontWeight: "bold" }}>Website</span>
                  </Label>
                  <Input
                    type="text"
                    name="website"
                    id="website"
                    value={formData.website}
                    onChange={handleChange}
                    placeholder="Enter website URL"
                  />
                  {errors.website && (
                    <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                      {errors.website}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="uDiseNo">
                    <span style={{ fontWeight: "bold" }}>UDISE Number</span>
                  </Label>
                  <Input
                    type="text"
                    name="uDiseNo"
                    id="uDiseNo"
                    value={formData.uDiseNo}
                    onChange={handleChange}
                    maxLength="11"
                  />
                  {errors.uDiseNo && (
                    <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                      {errors.uDiseNo}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="affiliationNo">
                    <span style={{ fontWeight: "bold" }}>Affiliation No.</span>
                  </Label>
                  <Input
                    type="text"
                    name="affiliationNo"
                    id="affiliationNo"
                    value={formData.affiliationNo}
                    onChange={handleChange}
                    maxLength="12" 
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ''); 
                    }}
                  />
                  {errors.affiliationNo && (
                    <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                      {errors.affiliationNo}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="schoolCode">
                    <span style={{ fontWeight: "bold" }}>School Code</span>
                  </Label>
                  <Input
                    type="text"
                    name="schoolCode"
                    id="schoolCode"
                    value={formData.schoolCode}
                    onChange={handleChange}
                    maxLength="12" 
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ''); 
                    }}
                  />
                  {errors.schoolCode && (
                    <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                      {errors.schoolCode}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="state" className="fw-bold">
                    State
                  </Label>
                  <Select
                    options={states}
                    onChange={handleStateChange}
                    placeholder="Select a state"
                    isClearable={true}
                    styles={customStyles}
                    value={
                      formData.state
                        ? states.find((state) => state.value === formData.state)
                        : null
                    }
                  />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label for="district" className="fw-bold">
                    District
                  </Label>
                  <Select
                    options={districts}
                    onChange={handleDistrictChange}
                    placeholder="Select a district"
                    isClearable={true}
                    styles={customStyles}
                    value={
                      formData.district
                        ? districts.find((district) => district.value === formData.district)
                        : null 
                    }
                  />
                  {errors.district && <div className="error-message">{errors.district}</div>}
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup>
                  <Label for="city"><span style={{ fontWeight: "bold" }}>City/Town/Village</span></Label>
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    value={formData.city}
                    onChange={handleChange}


                  />
                  {errors.city && (
                    <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                      {errors.city}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="address"><span style={{ fontWeight: "bold" }}>Address</span></Label>
                  <Input
                    type="textarea"
                    name="address"
                    id="address"
                    value={formData.address}
                    onChange={handleChange}
                    style={{ maxHeight: '60px' }}
                  />
                  {errors.address && (
                    <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                      {errors.address}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="pincode">
                    <span style={{ fontWeight: "bold" }}>PIN Code</span>
                  </Label>
                  <Input
                    type="text"
                    name="pincode"
                    id="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    maxLength="6" 
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ''); 
                    }}
                  />
                  {errors.pincode && (
                    <div style={{ color: 'red', marginTop: '0.25rem', fontSize: '0.875rem' }}>
                      {errors.pincode}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col xs={12} sm={6} md={3}>
                <FormGroup>
                  <Label for="image">
                    <span style={{ fontWeight: 'bold' }}>Branch Logo</span>
                  </Label>
                  <div style={{ position: 'relative', display: 'inline-block' , width: '100%'}}>
                    <div className="d-flex align-items-center">
                      <Input
                        type="file"
                        name="image"
                        id="image"
                        style={{ width: '100%'}}
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png, image/jpg"
                      />
                      {imagePreview && (
                        <RxCross2
                          onClick={() => handleClearFile('image')}
                          style={{
                            position: 'absolute',
                            top: '7px',
                            right: '8px',
                            cursor: 'pointer',
                            color: 'red',
                            fontSize: '20px',
                            backgroundColor: '#ffffff'
                          }}
                        />
                      )}
                    </div>
                    <span style={{ fontSize: '12px', color: '#d83030' }}>Maximum Upload Limit: 5MB</span>
                  </div>
                </FormGroup>
              </Col>
              {imagePreview && (
                <Col xs={12} sm={6} md={3}>
                  <div className="d-flex flex-column align-items-start mt-0 mb-3 mb-md-0">
                    <>
                      <Label>
                        <span style={{ fontWeight: 'bold' }}>Preview:</span>
                      </Label>
                      <img
                        src={imagePreview}
                        alt="Branch Preview"
                        style={{ width: '200px', height: '80px', objectFit: 'contain' }}
                      />
                    </>
                  </div>
                </Col>
              )}
              <Col xs={12} sm={6} md={3}>
                <FormGroup>
                  <Label for="principalSignImage">
                    <span style={{ fontWeight: "bold" }}>Principal's Signature</span></Label>
                  <div style={{ position: 'relative', display: 'inline-block' , width: '100%'}}>
                    <div className="d-flex align-items-center"></div>
                    <Input
                      type="file"
                      name="principalSignImage"
                      id="principalSignImage"
                      style={{ width: '100%'}}
                      onChange={handleFileChange}
                      accept="image/jpeg, image/png, image/jpg"
                    />
                    <span style={{ fontSize: "12px", color: "#d83030" }}>Maximum Upload Limit: 5MB</span>

                    {formData['principalSignImage'] && (
                      <RxCross2
                        onClick={() => handleClearFile('principalSignImage')}
                        style={{
                          position: 'absolute',
                          top: '7px',
                          right: '8px',
                          cursor: 'pointer',
                          color: 'red',
                          fontSize: '20px',
                          backgroundColor: '#ffffff'
                        }}
                      />

                    )}
                  </div>
                </FormGroup>
              </Col>
              {signImagePreview && (
                <Col xs={12} sm={6} md={3}>
                  <div className="d-flex flex-column align-items-start  mt-0 mb-3 mb-md-0">
                    <Label><span style={{ fontWeight: "bold" }}>Preview:</span></Label>
                    <img
                      src={signImagePreview}
                      alt="Principal's Signature Preview"
                      style={{ width: '200px', height: '80px', objectFit: 'contain' }}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <div className="d-flex justify-content-center mt-3">
              <Button className="btn-no-radius" color="primary" type="submit">
                {loading ? <Spinner size="sm" /> : 'Save'}
              </Button>
              <Button color="secondary" type="button" onClick={handleCancel} className="btn-no-radius mx-2">
                Cancel
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};


export default CreateBranch;