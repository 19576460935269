import React, { useEffect, useState } from 'react';
import { Table, Card, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { IoIosCalendar } from "react-icons/io";
import axiosInstance from '../../../middleware/axiosInstance';

const CreateAcademicYear = () => {
  const [formData, setFormData] = useState({
    year: '',
  });
  const [loading, setLoading] = useState(true);
  const [academicYears, setAcademicYears] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const fetchAcademicYears = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/academicyears');
      setAcademicYears(response.data);
    } catch (err) {
      console.error("Error fetching academic years:", err);
    } finally {
      setLoading(false);
    }
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCreateAcademicYear = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axiosInstance.post('/create/academicyear', formData);
      fetchAcademicYears();
      toggleModal();
    } catch (err) {
      console.error("Error creating academic year:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAcademicYears();
  }, []);

  return (
    <div>
      
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '90vh' }}>
            <Spinner color="primary" />
          </div>
        ) : academicYears.length === 0 ? (
          <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '90vh' }}>
            <div className="text-center">
              <div className="d-flex flex-column align-items-center">
                <IoIosCalendar size={90} color="#888" />
                <h4 className="alert-heading mb-3 mt-3">No Academic Years Found</h4>
                <Button className='btn-no-radius theme-button' color="primary" onClick={toggleModal}>
                  Add Academic Year
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <CardBody>
           <Card  style={{ boxShadow: 'none', marginBottom: '10px', borderRadius: '0' }} className='p-2 px-3'>
            <div className="d-flex justify-content-between align-items-center ">
              <CardTitle tag="h4" >Academic Years</CardTitle>
              <Button style={{ border: "none" }} className='btn-no-radius theme-button' color="primary" onClick={toggleModal}>
                Add Academic Year
              </Button>
            </div>
            </Card>
            <Card  className=" px-3 pt-0" style={{ borderRadius: '0', minHeight: '400px'}}>
            <Table bordered striped responsive className='mt-3'>
              <thead>
                <tr>
                  <th>Academic Year</th>
                </tr>
              </thead>
              <tbody>
                {academicYears.map((year) => (
                  <tr key={year._id}>
                    <td>{year.year}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </Card>
          </CardBody>
        )}
      

      <Modal className='mt-5 pt-3' isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add New Academic Year</ModalHeader>
        <Form onSubmit={handleCreateAcademicYear}>
          <ModalBody>
            <FormGroup>
              <Label for="year">Academic Year</Label>
              <Input
                type="text"
                name="year"
                id="year"
                value={formData.year}
                onChange={handleChange}
                placeholder="Enter academic year (e.g., 2024-25)"
                required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button className='btn-no-radius' color="primary" type="submit" disabled={loading}>
              Save
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateAcademicYear;
