
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Row,
  Col
} from 'reactstrap';

import { FaSearch, FaRegEdit, FaFileAlt } from 'react-icons/fa';
import { AiOutlineDelete , AiOutlineEye } from "react-icons/ai";
import { NavLink, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import axiosInstance from '../../../middleware/axiosInstance';
import Select from 'react-select';

const Students = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ branchId: '', classId: '', sectionId: '' });
  const [branches, setBranches] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [students, setStudents] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(75);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [noDataFound, setNoDataFound] = useState(false);

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axiosInstance.get('/branches');
        setBranches(response.data);
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };    
    fetchBranches();
  }, [token]);
  
  useEffect(() => {
    const fetchClasses = async () => {
      if (formData.branchId) {
        try {
          const response = await axiosInstance.get('/classes/branch', {
            params: { branchId: formData.branchId }
          });
          setClasses(response.data);
          setFormData(prev => ({ ...prev, classId: '', sectionId: '' }));
        } catch (error) {
          setError('Failed to fetch classes.');
        }
      } else {
        setClasses([]);
        setSections([]);
        setFormData(prev => ({ ...prev, classId: '', sectionId: '' }));
      }
    };
  
    fetchClasses();
  }, [formData.branchId, token]);
  
  useEffect(() => {
    const fetchSections = async () => {
      if (formData.branchId && formData.classId) {
        try {
          const response = await axiosInstance.get('/sections', {
            params: {
              branchId: formData.branchId,
              classId: formData.classId
            }
          });
          const sortedSections = response.data.sort((a, b) => a.sectionName.localeCompare(b.sectionName));
          setSections(sortedSections);
        } catch (error) {
          setError('Failed to fetch sections.');
        }
      } else {
        setSections([]);
      }
    };    
    fetchSections();
  }, [formData.branchId, formData.classId, token]);
  
  const fetchStudents = async () => {
    try {
      const response = await axiosInstance.get('/students/paginated', {
        params: {
          ...formData,
          searchTerm: searchTerm.trim(),
          page: currentPage,
          limit: studentsPerPage
        }
      });

       const sortedStudents = response.data.students.sort((a, b) => a.roll - b.roll);


      setStudents(sortedStudents);
      setTotalStudents(response.data.totalStudents);
      setNoDataFound(sortedStudents.length === 0);
    } catch (error) {
      setError('No Data Found');
    }
  };

  const debouncedFetchStudents = debounce(fetchStudents, 500);

  useEffect(() => {
    debouncedFetchStudents();
  }, [searchTerm, formData, currentPage]);

  const handleChange = ({ target: { name, value } }) => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
      ...(name === 'classId' ? { sectionId: '' } : {}), 
    }));
    if (name === 'branchId' || name === 'classId') {
      setSearchTerm('');
      setNoDataFound(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleEditStudent = id => navigate(`/updatestudent/${id}`);
  const handleDeleteStudent = async id => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`/deletestudent/${id}`);
        setStudents(prev => prev.filter(student => student._id !== id));
        setSuccessMessage('Student deleted successfully.');
      } catch (error) {
        setError('Failed to delete the student.');
      }
    }
  };

  const handleDetailsStudent = id => navigate(`/singlestudent/${id}`);
  const handleDocumenetStudent = id => navigate(`/studentdocument/${id}`);


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#FB0F01' : provided.borderColor,
      boxShadow: state.isFocused ? '0 0 0 1px #FB0F01' : null,
      borderWidth: state.isFocused ? '0px' : '1px', 
      transition: 'border-color 0.2s, box-shadow 0.2s', 
      '&:hover': {
        borderColor: '#FB0F01',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#FDD7C2' : '#FFFFFF',
      color: state.isFocused ? 'black' : 'black',
      '&:active': {
        backgroundColor: '#E8F0FE',
      },
    }),
  };


  const renderTable = () => (
    <div>
      <div className='px-0 pb-3'>
        <CardTitle tag="h4" className='mt-3 mb-1'>Student List</CardTitle>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table bordered striped responsive>
          <thead style={{ backgroundColor: '#f8f9fa', color: '#495057' }}>
            <tr>
              <th>Roll No.</th>
              <th>Student Name</th>
              <th>Class</th>
              <th>Branch</th>
              <th>Academic Year</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {students.length ? students.map(student => (
              <tr key={student._id}>
                <td>{student.roll}</td>
                <td>{student.firstName} {student.lastName}</td>
                <td>{student.class_id?.className} {student.section_id ? ` (${student.section_id.sectionName})` : ''}</td>
                <td>{student.branch_id?.branchName}</td>
                <td>{student.academicyear_id?.year}</td>
                <td>
                  <AiOutlineEye title='Details' onClick={() => handleDetailsStudent(student._id)} style={{ cursor: 'pointer', margin: '0 6px' }} />
                  <FaRegEdit onClick={() => handleEditStudent(student._id)} style={{ cursor: 'pointer', margin: '0 6px' }} />
                  <AiOutlineDelete onClick={() => handleDeleteStudent(student._id)} style={{ cursor: 'pointer', margin: '0 6px' }} />
                </td>
              </tr>
            )) : (noDataFound && (
              <tr>
                <td colSpan="6" className="text-center">
                  <FaFileAlt className='mt-3' size={70} color="#888" />
                  <h4 className="alert-heading mb-3 mt-3">No Students Found</h4>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <style jsx="true">{`
          table td{
            white-space: nowrap; 
          }
            table th {
             white-space: nowrap; 
            }

          @media (max-width: 768px) {
            table td, table th {
              padding: 5px;
              white-space: nowrap; 
            }
          }

          @media (max-width: 576px) {
            table td, table th {
              white-space: nowrap; 
            }
          }
        `}</style>
      </div>
      {students.length > 0 && (
        <div className="d-flex justify-content-end align-items-center px-3 py-2">
          <Button
            style={{ border: "none" }}
            color="primary"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          >
            &lt;
          </Button>
          <span className="mx-2">{currentPage}</span>
          <Button
            style={{ border: "none", borderRadius:"0px"}}
            color="primary"
            disabled={currentPage === Math.ceil(totalStudents / studentsPerPage)}
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(totalStudents / studentsPerPage)))}
          >
            &gt;
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <>
    <Card  style={{ boxShadow: 'none', marginBottom: '10px', borderRadius: '0' }}>
      <div className="d-flex justify-content-between align-items-center px-3 pt-1">
        <CardTitle className='mt-2' tag="h4">Students</CardTitle>
        <NavLink to="/createstudent" className='pb-0 mb-0'>
          <Button className="btn-no-radius" color="primary">Add New Student</Button>
        </NavLink>
      </div>
      <hr className='mt-1' />
      <div className="px-3 pt-0">
        <Form>
        <Row> 
          <Col md={3}>
            <FormGroup>
              <Label for="branchId"><span style={{fontWeight:"bold"}}>Branch</span></Label>
              <Select
                id="branchId"
                options={branches.map(branch => ({ value: branch._id, label: branch.branchName }))}
                onChange={selected => {
                  handleChange({ 
                    target: { 
                      name: 'branchId', 
                      value: selected ? selected.value : '' 
                    } 
                  });
                }}
                value={branches.find(branch => branch._id === formData.branchId) ? { value: formData.branchId, label: branches.find(branch => branch._id === formData.branchId).branchName } : null}
                placeholder="Select a Branch"
                isSearchable={true}
                isClearable={true}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="classId"><span style={{fontWeight:"bold"}}>Class</span></Label>
              <Select
                id="classId"
                options={classes.map(classItem => ({ value: classItem._id, label: classItem.className }))}
                onChange={selected => {
                  handleChange({ 
                    target: { 
                      name: 'classId', 
                      value: selected ? selected.value : '' 
                    } 
                  });
                }}
                value={classes.find(classItem => classItem._id === formData.classId) ? { value: formData.classId, label: classes.find(classItem => classItem._id === formData.classId).className } : null}
                placeholder="Select a Class"
                isSearchable={true}
                isClearable={true}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="sectionId"><span style={{fontWeight:"bold"}}>Section</span></Label>
              <Select
                id="sectionId"
                options={sections.map(section => ({ value: section._id, label: section.sectionName }))}
                onChange={selected => {
                  handleChange({ 
                    target: { 
                      name: 'sectionId', 
                      value: selected ? selected.value : ''
                    } 
                  });
                }}
                value={sections.find(section => section._id === formData.sectionId) ? { value: formData.sectionId, label: sections.find(section => section._id === formData.sectionId).sectionName } : null}
                placeholder="Select a Section"
                isSearchable={true}
                isClearable={true}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        <Col md={3}>
            <FormGroup style={{ position: 'relative' }}>
            <Label for="sectionId"><span style={{fontWeight:"bold"}}>Search Student</span></Label>
              <Input
                type="text"
                id="searchInput"
                placeholder="Search By Student Details"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ paddingLeft: '2.5rem' }}
              />
              <FaSearch
                style={{
                  position: 'absolute',
                  top: '73%',
                  left: '10px',
                  transform: 'translateY(-50%)',
                  color: '#495057'
                }}
              />
            </FormGroup>
        </Col>

        </Row>
        </Form>
      </div>
    </Card>
    <Card  className=" px-3 pt-0" style={{ borderRadius: '0', minHeight: '400px'}}>
 {renderTable()}
    </Card>
    </>
  );
};

export default Students;


