import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import TopCards from '../../components/dashboard/TopCards';
import axiosInstance from '../../middleware/axiosInstance';
import LastestAdmission from '../../components/dashboard/LastestAdmission'
import StudentBirthday from '../../components/dashboard/StudentBirthday'

const Starter = () => {
  const [totalStudents, setTotalStudents] = useState(0); 
  const [totalClasses, setTotalClasses] = useState(0);   
  const [totalBranches, setTotalBranches] = useState(0); 
  const [year, setYear] = useState(0); 

  useEffect(() => {
    const fetchTotalStudents = async () => {
      try {
        const response = await axiosInstance.get('/totalstudents');
    
        if (response.status === 200) {
          setTotalStudents(response.data.totalStudents || 0); 
        } else {
          console.error('Failed to fetch total students');
        }
      } catch (error) {
        console.error('Error fetching total students:', error);
      }
    };
    

    fetchTotalStudents();
  }, []);


  useEffect(() => {
    const fetchAcademicYear = async () => {
      try {
        const response = await axiosInstance.get('/getAcademicYear')
        setYear(response.data.year)
        if (response.status === 200) {
        } else {
          console.error('Failed to fetch total classes')
        }
      } catch (error) {
        console.error('Error fetching total classes:', error)
      }
    }

    fetchAcademicYear()
 }, [])

  useEffect(() => {
 
    const fetchTotalClasses = async () => {
      try {
        const response = await axiosInstance.get('/totalclasses');
    
        if (response.status === 200) {
          setTotalClasses(response.data.totalClasses || 0); 
        } else {
          console.error('Failed to fetch total classes');
        }
      } catch (error) {
        console.error('Error fetching total classes:', error);
      }
    };
    
    fetchTotalClasses();
  }, []);


  
  useEffect(() => {
  
    const fetchTotalBranches = async () => {
      try {
        const response = await axiosInstance.get('/totalbranches');
    
        if (response.status === 200) {
          setTotalBranches(response.data.totalBranches || 0);
        } else {
          console.error('Failed to fetch total branches');
        }
      } catch (error) {
        console.error('Error fetching total branches:', error);
      }
    };
    
    fetchTotalBranches();
  }, []);

  return (
    <div style={{margin:'0px', padding:'0px'}}>
      <Row className='mx-0 px-0 pt-1 gx-2 '>
        <Col sm='6' lg='3'  className="mb-2">
        <TopCards
            bg='bg-light-info text-info'
            title='Academic Year'
            earning={year}
            icon='bi bi-bag'
             className="mb-0"
          />
        
          </Col>
        <Col sm="6" lg="3"  className="mb-2">
          <TopCards
            bg="bg-light-success text-success"
            title="Total Branches"
            earning={totalBranches} 
            icon="bi bi-building" 
         className="mb-0"
          />
        </Col>
        <Col sm="6" lg="3"  className="mb-2">
          <TopCards
            bg="bg-light-danger text-danger"
            title="Total Classes"
            earning={totalClasses} 
            icon="bi bi-collection" 
            className="mb-0"
          />
        </Col>
        <Col sm="6" lg="3"  className="mb-2"  >
          <TopCards
            bg="bg-light-warning text-warning"
            title="Total Students"
            earning={totalStudents} 
            icon="bi bi-person" 
           className="mb-0"
          />
        </Col>
      </Row>
      <div className='mx-0 px-0'>
        <div>
          <LastestAdmission />
        </div>
        <div>
          <StudentBirthday />
        </div>
      </div>
    </div>
  );
};

export default Starter;
