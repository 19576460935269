import React, { useEffect, useState } from 'react'
import { Card, CardBody, Table } from 'reactstrap'
import axiosInstance from '../../middleware/axiosInstance'
import { format } from 'date-fns'

const LastestAdmission = () => {
  const [admissionData, setAdmissionData] = useState([])

  useEffect(() => {
    const fetchAdmission = async () => {
      try {
        const response = await axiosInstance.get('/getLatestAdmission')

        if (response.status === 200) {
          const sortedAdmissions = (response.data.admissions || []).sort(
            (a, b) => new Date(b.admissionDate) - new Date(a.admissionDate)
          )
          setAdmissionData(sortedAdmissions)
        } else {
          console.error('Failed to fetch total students')
        }
      } catch (error) {
        console.error('Error fetching total students:', error)
      }
    }

    fetchAdmission()
  }, [])

  const formatDate = dateString => {
    return format(new Date(dateString), 'MMMM dd, yyyy')
  }

  return (
    <div className='px-1 w-100'>
      <Card className='mt-3 w-100'>
        <CardBody className='w-100'>
          <h5 className='fancy-heading'>Latest Admissions</h5>
          <div className='table-responsive' style={{ overflowX: 'auto' }}>
            <Table className='custom-table' bordered>
              <thead>
                <tr>
                  <th scope='col'>Sr.</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Branch</th>
                  <th scope='col'>Class</th>
                  <th scope='col'>Date</th>
                </tr>
              </thead>
              <tbody>
                {admissionData?.map((student, index) => (
                  <tr key={index}>
                    <th scope='row'>{index + 1}</th>
                    <td>
                      {student?.firstName} {student?.lastName}
                    </td>
                    <td>{student?.branch_id?.branchName || 'N/A'}</td>
                    <td>
                      {student?.class_id?.className || ' '}
                      {student.section_id
                        ? ` (${student.section_id.sectionName})`
                        : ''}
                    </td>
                    <td>{formatDate(student?.admissionDate)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <style jsx='true'>{`
              table td,
              table th {
                white-space: nowrap; 
              }

              @media (max-width: 768px) {
                table td,
                table th {
                  padding: 5px;
                }
              }

              @media (max-width: 576px) {
                table td,
                table th {
                }
              }
            `}</style>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default LastestAdmission