import React, { useEffect, useState } from 'react'
import { Card, CardBody, Table } from 'reactstrap'
import axiosInstance from '../../middleware/axiosInstance'
import { format } from 'date-fns';


const StudentBirthday = () => {
  const [StudentBirthdayData, setStudentBirthdayData] = useState([])

  useEffect(() => {
    const fetchAdmission = async () => {
      try {
        const response = await axiosInstance.get('/getStudentBirhtday')
        setStudentBirthdayData(response.data.birthdayStudents)
      } catch (error) {
        console.error('Error fetching total students:', error)
      }
    }

    fetchAdmission()
  }, [])

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'MMMM dd, yyyy');
  };

  return (
    <div className='px-1'>
      <Card className='mt-3'>
      <CardBody>
        <h5 className='fancy-heading'>Today's birthdays</h5>
        <div className='table-responsive ' style={{ overflowX: 'auto' }}>
          <Table className='custom-table' bordered>
            <thead className='thead-dark'>
              <tr>
                <th scope='col'>Sr.</th>
                <th scope='col'>Name</th>
                <th scope='col'>Branch</th>
                <th scope='col'>Class</th>
                <th scope='col'>DOB</th>
              </tr>
            </thead>
            <tbody>
              {StudentBirthdayData?.map((student, index) => (
                <tr key={index}>
                  <th scope='row'>{index + 1}</th>
                  <td>
                    {student.firstName} {student.lastName}
                  </td>
                  <td>{student.branchName}</td>
                  <td>
                    {student.className} {student?.sectionName ? `(${student.sectionName})` : ''}
                  </td>
                  <td>{formatDate(student.dateOfBirth)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <style jsx="true">{`
          table td, table th {
            white-space: nowrap; 
          }

          @media (max-width: 768px) {
            table td, table th {
              padding: 5px;
           
            }
          }

          @media (max-width: 576px) {
            table td, table th {
            }
          }
        `}</style>
        </div>
      </CardBody>
    </Card>
    </div>
  )
}

export default StudentBirthday
